import axios from 'axios'
import NProgress from 'nprogress'
import router from "../router";
import 'nprogress/nprogress.css'
import {Message} from 'element-ui'

const request = axios.create({
    // baseURL: 'http://127.0.0.1:3000',
    baseURL: 'https://api.yonnxu.cn',
    // baseURL: 'http://120.78.95.209:3000',
    timeout: 50000
})

// 请求拦截
request.interceptors.request.use(config => {
        NProgress.start()
        const token = window.localStorage.getItem('token')
        const isTimeExpired = window.localStorage.getItem('time')
        let nowTime = new Date().getTime()
        if (nowTime > isTimeExpired) {
            console.log(nowTime)
            console.log(isTimeExpired)
            router.push('/login')
            return config
        }
        if (token) {
            config.headers.Authorization = token
        }
        return config
    },
    error => {
        console.info(error)
        return Promise.reject(error)
    }
)


// 响应拦截
request.interceptors.response.use(response => {
        NProgress.done()
        const res = response.data

        if (res.status !== 200) {
            // Token是否过期
            console.log('过期');
            if (res.status === 401) {
                router.push('/login')
            }
            let msg = res.message || 'Error'
            Message.error(msg)
            return Promise.reject(new Error(msg))
        }
        return res
    },
    error => {
        console.info(error)
        Message.error(error.message)
        return Promise.reject(error)
    }
)

export default request